




.avatar {

  width: 180px;
  height: 180px;




  &.listing, &.profile {
    background: $primary;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-size: 2rem;
    font-weight: 500;
    border-radius: 4px 4px 24px 4px;

    &.logo {
      img {
        max-height: 100%;
      }
    }
  }

  &.listing &.logo {
    border-radius: 4px;
  }

  &.profile {
    border-radius: 100%;
    &.logo {
      background-color: transparent;
      img {
        border-radius: 100%;

      }
    }

  }

}


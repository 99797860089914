.listing-tag {
  border-radius: 8px;
  border: 1px solid $grey-lighter;
  padding: 8px 12px 8px 8px;
  font-size: 12px;
  display: inline-flex;

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .listing {
    background-color: $primary;
    border-radius: 4px;
    color: white;
    height: 24px;
    width: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    &.logo {
      border-radius: 4px;

      img {
        max-height: 100%;
      }
    }
  }
}

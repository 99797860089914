
@import "variables/colors";


@import "../../node_modules/bulma/bulma.sass";




//@import "~@fortawesome/fontawesome-free/scss/";



// Components
@import "components/login";
@import "components/avatar";
@import "components/auth_modal";
@import "components/email_verification_modal";
@import "components/modal";
@import "components/diddir-logo";
@import "components/card";
@import "components/listing_details";
@import "components/tag";
.link {

  //background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  /*input has OS specific font-family*/
  cursor: pointer;
  overflow-wrap: anywhere;
  background: transparent;

  &:hover {
    opacity: 70%;
  }

}

.navbar.is-spaced a.navbar-item {
  border-radius: 0px;
}

.navbar.is-spaced {
  padding: 1rem 2rem 0rem;
}



.modal {
  .modal-background {
    opacity: 0.3;
    background-color: #0A0A0A;
  }

  .modal-content {
    background-color: white;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 40px;
  }
}


.auth-modal {
  position: absolute;
  margin: 20px;
  width: 80vw;
  height: 80vh;
  background: white;
  overflow: scroll;


  .is-background-primary {
    background: $primary;
    color: white;
    button {
      border: 1px solid white;
    }
  }

  .diddir-logo {
    position: absolute;
    left: 20px;
    top: 20px;
  }

  @include touch {
    .diddir-logo {
      position: relative;
    }
  }


  .inner {
    display: flex;
    gap: 20px;


    align-self: center;
    align-items: center;
    flex-direction: column;
    .title {
      color: $primary;
    }

    .email-text {
      margin: 20px 0px;
    }

    a, button, .field {
      width: 80%;
      max-width: 300px;
    }
  }

  .is-flex-gap {
    gap: 20px;
  }

  .subtitle {
    color: white;
    font-weight: 32px;
    font-weight: bold;
  }



}



.diddir-logo {

  padding: 2px 5px;
  border: 2px solid $primary;
  font-weight: bold;
  color: black;
  span {
    color: $primary;
  }
  &.is-secondary {
    border-color: white;
    color: white;
    span {
      color: white;
    }
  }



  border-bottom-right-radius: 10px;
}

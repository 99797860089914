

.email-verification-modal {

  padding: 20px;

  position: absolute;
  gap: 20px;
  max-width: 600px;
  background-color: white;

  .title {
    color: $primary;

  }

  .link {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

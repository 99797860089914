$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";

@import "App";

a {
  overflow-wrap: anywhere;
}

body, #root {
  background-color: $white-bis;
  color: $black;
}

html, body {
  height: 100%;
}

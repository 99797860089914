$primary: #1A8572;
$black: #0A0A0A;
$grey-dark: #4A4A4A;
$grey-light: #B5B5B5;
$grey-lighter: #DBDBDB;
$white-ter: #F5F5F5;
$white-bis: #FAFAFA;


$link: $primary;

$navbar-item-color: $primary;
$navbar-item-active-color: $primary;
$navbar-item-hover-color: $primary;
$navbar-tab-active-color: $primary;
$navbar-item-active-background-color: #FAFAFA;
$navbar-tab-active-border-bottom-color: $primary;
$navbar-tab-hover-border-bottom-color: $primary;
$navbar-tab-active-border-bottom-style: solid 3px;
$navbar-tab-active-background-color:  #FAFAFA;

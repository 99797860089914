.listing-details {

  .listing-header {
    opacity: 90%;
    height: 160px;

    .columns {
      margin-left: 200px;
    }

  }

  .logo-column {
    width: 180px;
    position: relative;
    top: -40px;
    margin-right: 20px;
    left: 30px;
    .avatar {


    }
  }
}

.card {
  padding: 24px;
  background: $white;
  border: 1px solid var(--);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);
  border-radius: 4px 4px 24px 4px;

  &.listing-search-result:hover {
    box-shadow: 0 0.5em 1em -0.125em hsl(0deg 0% 4% / 20%), 0 0 0 1px #1a8572;
  }

  margin: 40px 20px;
  width: 100%;

  .avatar.listing {
    width: 100px;
    height: 100px;
  }



}





.modal {


  .close-modal {
    position: absolute;
    right: 20px;
    top: 20px;
    background: none;
    border: none;
    &:hover {
      cursor: pointer;
    }
  }


}
